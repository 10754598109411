/* You can add global styles to this file, and also import other style files */

:where(
    app-category-news,
    app-results,
    app-donors,
    app-detail,
    app-leader,
    app-whatsapp-banner,
    app-follow
) :where(
    *,
    *::before,
    *::after
) {
    margin: 0;
    padding: 0;

    font: inherit;

    box-sizing: border-box;
}

@media screen and (min-width: 1441px) {
    :is(
        app-category-news,
        app-results,
        app-donors
    ) > :first-child {
        --banner-padding-inline-start: 25% !important;
        --banner-padding-inline-end: 25% !important;
    }
}

:root {
    font-size: 10px;
}

body {
    height: 100%;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    margin: 0;
    font-family: Roboto;
    font-size: 1.6rem;
    color: #212121 !important;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

mat-icon path {
    fill: currentColor
}

@media only screen and (min-width: 1441px) {
    body {
        /* position: relative; */
        /* max-width: 1440px; */
        /* padding-left: 28vw;
        padding-right: 28vw; */
        /* right: 50vw; */
        position: relative;
        width: 100%;
        /* margin-left: +20vw; */
        /* left: 50%;
        margin-left: -720px; */
        /* width / 2 */
        /* width: 100px; */
        /* padding-left: +25%;
        padding-right: +25%; */
        /* width / 2 */
    }
}

h1 {
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 58.8px */
    letter-spacing: 0.35px;
}

h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 120%;
    /* 32px */
    letter-spacing: 0.5px;
}

h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.5px;
}

p,
li {
    font-size: 16px;
    font-weight: 350;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.5px;
}

video, img {
    max-width: 100%;
    height: auto;
}
